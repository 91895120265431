import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ padding: 5 }}>Drop-a-Prayer + Slack</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <br/>
        <h2>What is Drop-a-Prayer?</h2>
        <p className="App-text">
          Drop-a-Prayer is simple application that allows anyone to send a sms message via a Drop-a-Prayer number.
          The SMS message(s) is routed too a <a href="https://slack.com/downloads" target="_blank">Slack Channel</a> allowing churches, outreaches and other organizations to setup a call like sms center.
          So how can the Body of Christ use this to equip the Saints <b>(Ephesians 4:12-16)</b> and fulfill The Great Commission
          <b>(Matthew 28:16-20)</b>?
          Download the Drop-a-Prayer PDF for more insight or reach out to us for more information.
        </p>
        <a
          className="App-link"
          href="drop-a-prayer-wires.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Drop a Prayer PDF
        </a>
        <a
          className="App-link"
          href="mailto:info@dropaprayer.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@dropaprayer.com
        </a>
        <br/>
        <h2>What is Slack?</h2>
        <iframe src="https://www.youtube.com/embed/W8_tGC8pNvI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <br/>
        <br/>
      </header>
      <br/>
      <br/>
      {/* <Router>
        <Route path="/" render={Home} />
      </Router> */}
    </div>
  );
}

export default App;
